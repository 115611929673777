/** @jsxImportSource theme-ui */

import {
  Flex,
  useColorMode,
  Text,
  Box,
  ThemeUIStyleObject,
  FlexProps,
  IconButton,
  IconButtonProps,
} from 'theme-ui'
import React, { useState } from 'react'

import { Link } from 'gatsby'

import { StaticImage } from 'gatsby-plugin-image'
import { Spin } from './burger/SpinBurger'
import { Dot } from './icons/dot'
import { pages } from '../lib/pages'
import { BmcIcon } from './icons/bmc-brand-icon'

export const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Header />
      <Flex as="main" sx={{ flexDirection: 'column', padding: [4, 6] }}>
        {children}
      </Flex>
    </>
  )
}

const Header: React.FC = () => {
  return (
    <Flex
      as="header"
      sx={{ color: 'text', bg: 'muted', alignItems: 'stretch' }}
    >
      <Link
        to="/"
        sx={{
          ...linkStyles,
          flexShrink: '0',
        }}
      >
        <StaticImage
          src="../images/Mudae.png"
          alt="Mudae Bot Logo"
          layout="fixed"
          height={48}
          width={48}
          sx={{ borderRadius: 9999 }}
        />
        <Text sx={{ padding: 2, fontWeight: 'bold' }}>Mudae Tools</Text>
      </Link>
      <br />

      <NavigationMenu />
    </Flex>
  )
}

const linkStyles: ThemeUIStyleObject = {
  padding: 2,
  display: 'flex',
  alignItems: 'center',
  color: 'inherit',
  textDecoration: 'none',
  '&:hover,&.active': {
    textDecorationColor: 'primary',
    textDecorationLine: 'underline',
    textDecorationStyle: 'wavy',
    textDecorationThickness: '3px',
    textDecorationSkipInk: 'none',
  },
}

const useToggle = (
  initialState: boolean,
): [boolean, (state?: boolean) => void] => {
  const [state, setState] = useState(initialState)

  return [
    state,
    (nextState) => {
      if (nextState !== state) {
        setState(nextState ?? !state)
      }
    },
  ]
}
const NavigationMenu: React.FC = () => {
  const [menuOpen, toggleMenuOpen] = useToggle(false)

  return (
    <>
      <Center
        sx={{
          marginInlineStart: 'auto',
          display: ['flex', 'none'],
          paddingInline: 3,
        }}
      >
        <IconButton sx={{ size: 'unset' }} onClick={() => toggleMenuOpen()}>
          <Spin size={32} toggled={menuOpen} />
        </IconButton>
      </Center>
      <nav
        role="navigation"
        className={menuOpen ? 'menu-open' : ''}
        sx={{
          flexGrow: '1',
          flexDirection: 'column',
          position: 'fixed',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
          backdropFilter: 'blur(4px)',
          zIndex: 1,
          padding: 3,
          top: (theme) => `calc(${theme.space![2]}px + 48px)`,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'none',
          '&.menu-open': {
            display: 'flex',
            opacity: 1,
          },
          '@media screen and (min-width: 40em)': {
            display: 'flex',
            justifyContent: 'unset',
            alignItems: 'unset',
            backdropFilter: 'unset',
            padding: 0,
            position: 'unset',
            opacity: 1,
            flexDirection: 'row',
            transition: 'none',
          },
        }}
      >
        {pages.map(({ to, title, hideInHeader }) => (
          <Link
            key={to}
            to={to}
            activeClassName="active"
            onClick={() => toggleMenuOpen(false)}
            sx={{
              ...linkStyles,
              fontSize: [4, 2],
              marginInlineStart: [0, 2],
              display: ['flex', hideInHeader ? 'none' : 'flex'],
            }}
          >
            {title}
          </Link>
        ))}
        <Box sx={{ margin: 'auto' }} />
        <a
          href="https://buymeacoffee.com/maistho"
          target="_blank"
          className="plausible-event-name=bmc"
          sx={{
            ...linkStyles,
            fontSize: 2,
            marginInlineStart: [0, 2],
            flexShrink: 0,
          }}
        >
          <BmcIcon
            sx={{ fill: 'bmcIcon', height: '32px', marginInlineEnd: 1 }}
          />
          Buy me a coffee
        </a>
        <Center sx={{ paddingInline: 3 }}>
          <ColorModeButton sx={{}} />
        </Center>
      </nav>
    </>
  )
}
const ColorModeButton: React.FC<IconButtonProps> = ({ ...props }) => {
  const [colorMode, setColorMode] = useColorMode()

  return (
    <IconButton
      {...props}
      aria-hidden
      title={`Toggle ${colorMode} mode`}
      variant="transparent"
      sx={{
        flexShrink: '0',
        width: 32,
        height: 32,
        p: 1,
        borderRadius: 99999,
      }}
      onClick={() => setColorMode(colorMode === 'dark' ? 'light' : 'dark')}
    >
      <Dot />
    </IconButton>
  )
}

const Center: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex {...props} sx={{ justifyContent: 'center', alignItems: 'center' }}>
      {children}
    </Flex>
  )
}
