import base from '@theme-ui/preset-base'
import { makeTheme } from '@theme-ui/css/utils'
import { merge } from 'lodash'
import { Theme } from '@theme-ui/css'

// prettier-ignore
const sizes = [0, 4, 8, 12, 16, 24, 32, 48, 64, 128, 256, 512,768,1024];

export const theme = makeTheme(
  merge({}, base, {
    config: {
      useLocalStorage: false,

      useColorSchemeMediaQuery: 'system',
    },
    colors: {
      text: '#000',
      background: '#fff',
      primary: '#11e',
      secondary: '#c0c',
      highlight: '#e0e',
      muted: '#f6f6ff',
      bmcIcon: '#0D0C22',
      modes: {
        dark: {
          text: '#fff',
          background: '#000',
          primary: '#0fc',
          secondary: '#0cf',
          highlight: '#f0c',
          muted: '#011',
          bmcIcon: '#c3c5e160',
        },
      },
    },
    fonts: {
      body: '"Avenir Next", system-ui, sans-serif',
      heading: 'inherit',
      monospace: 'Menlo, monospace',
    },
    fontWeights: {
      body: 400,
      heading: 600,
      bold: 700,
    },
    lineHeights: {
      body: 1.75,
      heading: 1.25,
    },
    buttons: {
      primary: {
        color: 'background',
        ':hover,:focus': {
          opacity: 0.85,
          outlineColor: 'primary',
          outlineOffset: '4px',
          outlineStyle: 'solid',
          outlineWidth: '2px',
        },
      },
      transparent: {
        color: 'inherit',
        bg: 'transparent',
        ':hover,:focus': {
          color: 'primary',
          outline: 'none',
          boxShadow: '0 0 0 2px',
        },
      },
    },
    forms: {
      textarea: {
        borderRadius: 1,
        minHeight: 9,
        resize: 'vertical',
        marginBlockEnd: 4,
      },
      switch: {
        flexShrink: 0,
      },
    },
    styles: {
      code: {
        fontFamily: 'monospace',
        color: 'secondary',
      },
    },
    cards: {
      primary: {
        padding: 3,
        marginBlock: 3,
        borderRadius: 1,
        boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
        backgroundColor: 'muted',
      },
    },

    space: sizes,
    sizes,
    radii: sizes,
  } as Theme),
)

export default theme
